.dropdown-item {

    &:hover,
    &:focus {
        color: var(--#{$variable-prefix}white);
        border-radius: $border-radius;
        cursor: pointer;
        background-color: var(--#{$variable-prefix}primary);
    }

    &.active,
    &:active {
        color: var(--#{$variable-prefix}white);
        border-radius: $border-radius;
        cursor: pointer;
    }
}

.dropdown-menu {
    li {
        margin: .5rem 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}