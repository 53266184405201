$icon-size: () !default;

$icon-size: map-merge(
  (
    "10": 10,
    "14": 14,
    "16": 16,
    "18": 18,
    "20": 20,
    "22": 22,
    "24": 24,
    "30": 30,
    "32": 32,
    "35": 35,
    "40": 40,
    "44": 44,
    "60": 60,
    "90": 90,
    "120": 120
  ), $icon-size
);

@each $key, $val in $icon-size {
  .h-#{$key}px {
    height: $val !important;
  }
  .w-#{$key}px {
    width: $val !important;
  }
}