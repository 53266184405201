.landing {
    &__banner_area {
        height: 100vh;
        video {
            top: 0;
            left: 0;
        }
        .overlay {
            background-color: rgba(#000, .8);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            // &::after {
            //     border-radius: 100%;
            //     width: 100vh;
            //     height: 100vh;
            //     background-color: rgba($colorGreen, .06);
            //     content: "";
            //     top: -10rem;
            //     left: -10rem;
            //     position: absolute;
            // }
        }
    }
    &__bannerContent {
        z-index: 4;
        .landing_logo {
            max-height: 6rem;
            max-width: 100%;
            filter: brightness(0) invert(1);
        }
    }
    &__bodyRow {
        padding: 6rem 0 5rem 0;
        &:nth-child(even) {
            background-color: rgba(#01bba8, .05);
            &>.container>.row {
                flex-direction: row-reverse
            }
        }
    }
    .bodyRow_ttl {
        position: relative;
        .bodyRow_ttl_border {
            height: .22rem;
            width: 4rem;
        }
    }
    .loginArea {
        width: 36rem;
        max-width: 100%;
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            content: "";
            background-color: rgba(#01bba8, .8);
            height: 100%;
            border-radius: 1rem;
            transform: skewX(6deg);
        }
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            content: "";
            background-color: rgba(#fff, .8);
            height: 100%;
            border-radius: 1rem;
            transform: skewX(-5deg);
        }
        .loginAreaIn {
            padding: 3rem;
            position: relative;
            z-index: 1;
        }
    }

}