.body-class-1 {
  scroll-behavior: smooth;
}

.main-img {
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    h1 {
      margin: 0;
      font-size: 4.375rem;
      color: $white;
      span {
        padding: 0.375rem 0.875rem;
        display: inline-block;
      }
    }
  }
}
.bd-heading-1 {
  .bd-example {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0rem;
    }
  }
}

.bd-cheatsheet {
  margin-top: -2rem;
  section {
    article {
      .card {
        &.iq-document-card {
          padding: $spacer * 1.5;
          .tab-content {
            pre {
              &.language-markup {
                margin: unset;
              }
            }
          }
        }
      }
    }
  }
}

.bd-aside {
  &.card {
    &.iq-document-card {
      padding: $spacer * 1.5;
      margin-top: -2rem;
    }
  }
}

/* Table of contents */
.bd-aside {
  a {
    padding: 0.25rem 0.5rem;
    margin-top: 0.125rem;
    margin-left: 0.25rem;
    color: rgba($body-color, 0.95);
    text-decoration: none;
    &:hover,
    &:focus {
      background-color: rgba($primary, 0.1);
    }
  }
  .active {
    font-weight: 600;
    background-color: rgba($primary, 0.1);
    color: var(--#{$variable-prefix}primary);
  }
  .btn {
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    color: $body-color;
    border: 0;
    .right-icon {
      transition: transform 0.35s ease;
    }
    &:hover,
    &:focus {
      background-color: rgba($primary, 0.1);
      color: var(--#{$variable-prefix}primary);
    }
  }
  .btn[aria-expanded="true"] {
    .right-icon {
      transform: rotate(90deg);
    }
  }
}

.scrollspy-example {
  position: relative;
  height: 12.5rem;
  margin-top: 0.5rem;
  overflow: auto;
}

[id="modal"] .bd-example .btn,
[id="buttons"] .bd-example .btn,
[id="tooltips"] .bd-example .btn,
[id="popovers"] .bd-example .btn,
[id="dropdowns"] .bd-example .btn-group,
[id="dropdowns"] .bd-example .dropdown,
[id="dropdowns"] .bd-example .dropup,
[id="dropdowns"] .bd-example .dropend,
[id="dropdowns"] .bd-example .dropstart {
  // margin: 0 1rem 1rem 0;
  margin-top: 0.5rem;
}

/* Layout */
@include media-breakpoint-up(xl) {
  .body-class-1 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: auto;
  }

  .bd-aside {
    &.sticky-xl-top {
      top: 2rem !important;
    }
  }

  .bd-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    grid-column: 1 / span 3;
  }

  .sticky-xl-top {
    z-index: 2;
  }

  .bd-cheatsheet,
  .bd-cheatsheet section,
  .bd-cheatsheet article {
    display: grid;
    gap: $grid-gutter-width;
    grid-template-columns: 3fr 1fr;
    grid-column: span 2 / span 2;
    grid-template-rows: auto;
  }

  .bd-aside {
    grid-area: 1 / 1;
    scroll-margin-top: 4rem;
  }

  .bd-cheatsheet section,
  .bd-cheatsheet section > .iq-side-content {
    top: 2rem;
    scroll-margin-top: 0;
  }

  .bd-cheatsheet {
    // grid-area: 1 / 2;
    article,
    .bd-heading {
      top: calc(7.2rem + #{$grid-gutter-width});
      scroll-margin-top: calc(7.8rem + #{$grid-gutter-width});
    }
    .bd-heading {
      z-index: 1;
      order: 2;
      .card {
        margin-bottom: 0;
      }
    }
    article {
      .iq-document-card {
        margin-bottom: unset;
        &.iq-doc-head {
          margin-top: -6.8rem;
        }
      }
    }
  }
}
//***********************************
// off canvas
//*********************************//

@include media-breakpoint-between(md, xl) {
  .mobile-offcanvas {
    width: calc(100% - 60%) !important;
  }
}

@include media-breakpoint-down(md) {
  .main-img {
    .container {
      h1 {
        font-size: 3.125rem;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .header {
    .container {
      max-width: unset !important;
    }
  }

  aside {
    &.mobile-offcanvas {
      margin-top: unset !important;
      .offcanvas-header {
        display: block;
      }
    }
  }
  .body-class-1 {
    padding: unset;
    &.container {
      max-width: unset;
    }
  }

  #content {
    .iq-side-content {
      .card {
        margin-top: 1.25rem;
      }
    }
  }

  .mobile-offcanvas {
    visibility: hidden;
    transform: translateX(-100%);
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1200;
    width: calc(100% - 25%);
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
    background: #ffffffd6;
    backdrop-filter: blur(0.5rem);
  }

  .mobile-offcanvas {
    &.show {
      visibility: visible;
      transform: translateY(0);
    }
    .container,
    .container-fluid {
      display: block;
    }
  }
}

@include media-breakpoint-up(xl) {
  .bd-aside.card.iq-document-card,
  .bd-cheatsheet {
    margin-top: -3rem;
  }
  aside {
    &.mobile-offcanvas {
      .offcanvas-header {
        display: none;
      }
    }
  }
  .bd-cheatsheet {
    section {
      .iq-side-content {
        grid-column-end: 3;
        .card {
          margin-bottom: 0;
        }
      }
    }
  }
}

body.offcanvas-active {
  overflow: hidden;
}
body.uikit {
  .btn-download {
    display: none;
  }
}

.uisheet {
  &.screen-darken {
    height: 100%;
    width: 0%;
    z-index: 30;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($gray-900, 0.6);
    transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;

    &.active {
      z-index: 10;
      transition: opacity 0.3s ease, width 0s;
      opacity: 1;
      width: 100%;
      visibility: visible;
    }
  }
}

//Back-To-Top

#back-to-top {
  display: inline !important;
  .top {
    bottom: 1.563rem;
    right: 1.563rem;
    z-index: 999;
  }
}

.middle {
  display: inline !important;
  .mid-menu {
    position: fixed;
    top: 9.375rem;
    left: 0rem;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    background: var(--#{$variable-prefix}primary);
    color: $white;
    padding: 0.2rem;
    border-radius: 0 1rem 1rem 0;
  }
}
.top-1 {
  top: $spacer;
}
.elem-list {
  max-height: calc(80vh + 4rem);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 10px;
  }
  /* Scroll Track */
  &::-webkit-scrollbar-track {
    background: $body-bg;
    border-radius: 10px;
  }
  /* Scroll Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($dark, 0.2);
    border-radius: 10px;
    transition: all 400ms ease;
    cursor: pointer;
  }
  /* Scroll Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($dark, 0.2);
    border-radius: 10px;
    transition: all 400ms ease;
    cursor: pointer;
  }
}
.add_floating__button {
  z-index: 1000;
  right: 2rem;
  bottom: 3rem;
  box-shadow: -0.2rem 0.4rem 1.4rem 0 rgba($dark, 0.4) !important;
  &:focus {
    border: 2px solid $white !important;
  }
}
.cursorPointer {
  cursor: pointer;
}

.w-fit-content {
  width: fit-content;
}

.z-10 {
  z-index: 10;
}

.floating-action {
  bottom: 5rem;
  right: 3rem;
}
// Modify B Content studio
.transition {
  transition: $transition-base;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-grab {
  cursor: grab;
}
.fileselect-check-box {
  z-index: 2;
  top: -0.8rem;
  right: -0.8rem;
  .check-mark {
    margin-top: 0.2rem;
    margin-right: 0.3rem;
  }
  .form-check {
    background-color: var(--bs-gray-200);
  }
  .form-check-input {
    width: 100%;
    z-index: 2;
    position: relative;
    height: 100%;
    border-radius: 50%;
    margin: 0;
    border: none;
    background-color: transparent;
    background-size: 1.5rem;
    background-position: bottom 0.8rem left 0.8rem;
  }
}
@mixin lineClamp($lineClampNum: 1) {
  -webkit-line-clamp: $lineClampNum;
  -webkit-box-orient: vertical;
  line-clamp: $lineClampNum;
  box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
// lineClamp
.textSingleLine {
  @include lineClamp;
}

.textDoubleLine {
  @include lineClamp(2);
}

.textThreeLine {
  @include lineClamp(3);
}

.textFourLine {
  @include lineClamp(4);
}

.scroll-content {
  // max-height: 40rem;
  max-height: 88vh;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba($primary, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary, 0.5);
    border-radius: 8px;
  }
}
.scrollbar-custom-xy {
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
    background-color: rgba($primary, 0.12);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary, 0.2);
    border-radius: 8px;
  }
}

.content-previewModal {
  &__left {
    padding-top: 7rem;
  }
  &__cover {
    height: 10rem;
    .overlay {
      z-index: 1;
    }
  }
  &__container {
    // min-height: 10rem;
    z-index: 2;
    * {
      width: 100% !important;
    }
    iframe {
    }
  }
}
.scroll-addcontent {
  // max-height: 40rem;
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // background-color: rgba($primary, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary, 0.5);
    // outline: 1px solid $primary;
    border-radius: 8px;
  }
}

.quillEditor .ql-blank {
  min-height: 8rem;
}

.tab-bottom-bordered .gray-tabs .nav-link {
  background-color: #fafafa;
}

.transform-rotate-45 svg {
  transform: rotate(45deg);
}
.tab-bottom-bordered--thin {
  border-bottom-width: 1px;

  .nav-tabs .nav-link {
    &::before,
    &::after {
      height: 1px;
      bottom: -1px;
    }
    &.active {
      &::before {
        right: 0.5rem;
      }
      &::after {
        left: 0.5rem;
      }
    }
  }
}

//channel css

// sidebar
.channel__sidebar__imgContainer {
  transition: all 0.4s ease-in;
  img {
    max-width: 10rem;
    max-height: 8rem;
    transition-delay: 0.05s;
  }
  .backto__prevPage {
    transition: all 0.4s ease-in;
    span.px-3 {
      transition: all 0.4s ease-in;
    }
    h5 {
      transition: all 0.4s ease-in;
    }
  }
}
.sidebar-mini {
  .channel__sidebar__imgContainer {
    img {
      max-width: 100%;
      max-height: none;
    }
  }
  .backto__prevPage {
    margin-left: 0.75rem;
    span.px-3 {
      padding: 0 !important;
      flex-grow: 1;
    }
    h5 {
      display: none;
    }
  }
}
// channel customization
.channelCustomization__color--squarePrnt {
  .form-control:not([type="color"]) {
    padding-right: 4.5rem !important;
  }
}
.channelCustomization__color {
  padding: 0.1rem;
  &--square {
    width: 2.6rem !important;
  }
}
.channelCustomization__img_check {
  .form-check-label {
    transition: all 0.15s ease-in;
  }
  .form-check-input:checked ~ .form-check-label {
    border-color: var(--bs-primary) !important;
    background-color: rgba(var(--bs-primary-rgb), 0.15);
    --bs-bg-opacity: 0.2;
    img {
      opacity: 0.8;
    }
  }
}

// createCampaignMeta
.createCampaignMetaMainBox {
  $leftSide_Width: 3.2rem;
  @include media-breakpoint-up(md) {
    // margin-left: -(calc($leftSide_Width / 2));
  }
  // width: 24rem;
  .nav {
    @include media-breakpoint-up(md) {
      // width: $leftSide_Width;
    }
  }
  .nav-link {
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(md) {
      width: $leftSide_Width;
      border-radius: 2rem 0 0 2rem;
      padding: 0.3rem;
      height: auto;
    }
    @include media-breakpoint-down(md) {
      border-radius: 50%;
      height: 2.4rem;
      width: 2.4rem;
      padding: 0;
      img,
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    & > :not(.createCampaignMeta__Nav__LinkActive) {
      position: relative;
      z-index: 1;
    }
    .createCampaignMeta__Nav__LinkActive {
      opacity: 0;
    }
    &:hover {
      .createCampaignMeta__Nav__LinkActive {
        opacity: 0.3;
      }
    }
    &.active {
      .createCampaignMeta__Nav__LinkActive {
        opacity: 1;
      }
    }
  }
  .tab-pane,
  .tab-pane > * {
    height: 100%;
  }
}

.channel__videos__table {
  table tbody tr td:first-child {
    max-width: calc(280px + 14rem);
  }
  .channel__videos__table__1stChild__content {
    max-width: 280px;
  }
}

.channel-campaign-title {
  max-width: 280px;
}

.one-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.channel-dropdown {
  margin-top: -3rem;
}
.loader-line {
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  z-index: 911;
}

.load {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0%;
  height: 4px;
  background-color: $primary;
}

// nav-tabs

.gray-tabs {
  a[aria-selected="false"] {
    border-right: 1px solid #f1f1f1;
    &:last-child {
      border-right: 0;
    }
  }
}

// India Map

.india-map {
  svg {
    position: relative !important;
  }
  .HoverInfo {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    position: absolute !important;
  }
  .map-legend {
    margin-top: -3rem;
    padding-bottom: 3rem;
    @include media-breakpoint-down(md) {
      margin-top: -1rem;
    }
  }
}
.india-map-dashboard {
  .HoverInfo {
    left: 40% !important;
    top: 0% !important;
    transform: translate(-40%, -30%);
    position: absolute !important;
  }
}

// video comments last column

@include media-breakpoint-up(md) {
  .commentsRow {
    .videoThumbnail {
      max-width: 30%;
    }
  }
}
@include media-breakpoint-down(md) {
  .video-logo {
    max-height: 6rem;
    max-width: 10rem;
  }
}

// video upload section

.thumbnailSliderArea {
  .sidebar-border-profile-card {
    padding: 1.4rem;
    .sidebar-border-profile-body {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      span {
        margin: 0 !important;
      }
      .sidebar-btn {
        padding: 1rem;
        margin: 0;
      }
    }
  }
}

@keyframes shine-alt {
  0% {
    opacity: 0;
    transform: translateX(-100px) skewX(-15deg);
    transition-property: opacity, transform;
  }
  11%,
  100% {
    opacity: 1;
    transform: translateX(300px) skewX(-15deg);
    transition-property: opacity, transform;
  }
}

.btn-shine::after {
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  left: 30px;
  filter: blur(5px);
  -webkit-animation: shine-alt2 5s ease-in-out infinite;
  animation: shine-alt2 5s ease-in-out infinite;
}

.subHeading::after {
  content: "";
  position: absolute;
  bottom: -0.7rem;
  left: 40%;
  right: 40%;
  width: 20%;
  border-top: 2px solid rgba($primary, 1);
}

.nav-pills-green {
  .active {
    background-color: $success !important;
  }
}
.max-tab-width {
  max-width: 17.5rem !important;
}

.summaryChart {
  div,
  svg {
    width: 100% !important;
    height: 100% !important;
    min-height: auto !important;
  }
}

.summaryChart {
  foreignObject,
  .apexcharts-legend {
    height: 0 !important;
    width: 0 !important;
  }
}
.bannerImage {
  max-height: 250px;
}
.creativeDetailsSwiper {
  .swiper-wrapper {
    align-items: center;
  }
}
.tableHeaderSwiper {
  .swiper-slide {
    width: auto;
  }
}

$calendarGreen: #5cbd9d !default;
.bg-calendarGreen {
  background-color: $calendarGreen;
}
@include media-breakpoint-up(md) {
  .dateRangeTime li {
    min-width: 10.4rem;
  }
}
.dateRange {
  .flatpickr-input {
    display: none;
  }
  .rangeMode {
    box-shadow: none !important;
  }
  .flatpickr-months .flatpickr-month {
    background: none;
    border: 1px solid $calendarGreen;
    color: $calendarGreen;
    font-weight: bold;
  }
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    fill: $calendarGreen;
  }
  .flatpickr-next-month svg,
  .flatpickr-prev-month svg {
    margin-top: -10px;
  }
  .flatpickr-monthDropdown-months,
  .numInput {
    font-size: 14px !important;
    background: none !important;
    border: 1px solid $calendarGreen;
    border-radius: 8px;
  }
  .flatpickr-monthDropdown-months {
    // display: flex;
    // gap: 0.5rem;
    // align-items: center;
    // justify-content: center;
  }
  .flatpickr-weekday {
    color: $white !important;
  }
  .flatpickr-innerContainer,
  .flatpickr-days {
    border-color: $calendarGreen !important;
  }
}

.bg-primary-light {
  background-color: rgba(239, 244, 254, 1);
}

// Events page timeline area

@include media-breakpoint-down(md) {
  .iq-timeline ul li {
    width: 100%;
  }
  .iq-timeline:before {
    left: 0;
    transform: translateX(0);
  }
  .iq-timeline ul li:nth-child(odd) {
    text-align: left;
  }
  .iq-timeline ul li:nth-child(even) .time,
  .iq-timeline ul li:nth-child(odd) .time {
    right: auto;
    left: 15px;
  }
  .content {
    margin-top: 50px;
    padding-left: 6px;
  }
  .iq-timeline ul li:nth-child(odd) .timeline-dots {
    right: auto;
    left: -7px;
  }
  .iq-timeline ul li .time {
    width: auto;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .iq-timeline ul li {
    cursor: auto;
  }
  .events-title {
    font-size: 1.2rem;
  }
}

.powered-by-logo {
  height: 25px;
}

.projectDetailsMatrix {
  // .matrixGradient {
  //   background: rgb(71,147,111);
  //   background: linear-gradient(264deg, rgba(71,147,111,1) 0%, rgba(17,60,140,1) 75%);
  //   &::before,
  //   &::after {
  //     position: absolute;
  //     width: 1.5rem;
  //     right: 0;
  //   }
  //   &::after {
  //     background-color: #7EB9B3;
  //   }
  //   &::before {
  //     background-color: #E8F3F1;
  //   }
  // }
}