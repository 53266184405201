.popover {
  background-color: $card-bg;
  color: $body-color;
}
.bs-popover-top {
  > .popover-arrow:after {
    border-top-color: $card-bg;
  }
}
.bs-popover-end {
  > .popover-arrow:after {
    border-right-color: $card-bg;
  }
}
.bs-popover-bottom {
  > .popover-arrow:after {
    border-bottom-color: $card-bg;
  }
}
.bs-popover-start {
  > .popover-arrow:after {
    border-left-color: $card-bg;
  }
}
