/*!
* Version: 1.1.0
* Template: Example Project
* Author: Clirnet
* Design and Developed by: Clirnet
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";
// Variables
@import "./hope-ui-design-system/variable";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";

@import "./bootstrap/mixins";

// Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./dark/index";
