// $dark-border-color: $border-color;
// agenda
.rbc-agenda-view table.rbc-agenda-table {
  border-color: $border-color;
  tbody > tr > td + td {
    border-left-color: $border-color;
  }
  .rbc-rtl & {
    tbody > tr > td + td {
      border-right-color: $border-color;
    }
  }
  tbody > tr + tr {
    border-top-color: $border-color;
  }
  thead > tr > th {
    border-bottom-color: $border-color;
  }
  .rbc-agenda-date-cell {
    background: $card-bg;
  }
}

// month
.rbc-month-view {
  border-color: $border-color;
  color: $white;
}
.rbc-show-more {
  color: $white;
}
.rbc-month-row + .rbc-month-row {
  border-top-color: $border-color;
}
.rbc-day-bg + .rbc-day-bg {
  border-left-color: $border-color;
}
.rbc-rtl + .rbc-rtl {
  border-right-color: $border-color;
}

// styles
.rbc-calendar {
  color: $white;
}
.rbc-off-range {
  color: $card-bg; //change
}
.rbc-off-range-bg {
  background: $body-bg; //change
}
.rbc-header {
  border-bottom-color: $border-color;
}
.rbc-header + .rbc-header {
  border-left-color: $border-color;
}
.rbc-today {
  background-color: var(--#{$variable-prefix}primary-tint-80); //change
}

// time-column
.rbc-day-slot .rbc-time-slot {
  border-top-color: $border-color;
}
.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  border-right-color: $border-color;
}
.rbc-timeslot-group {
  border-bottom-color: $border-color;
}

// time-grid
.rbc-time-view {
  border-color: $border-color;
  .rbc-allday-cell + .rbc-allday-cell {
    border-left-color: $border-color;
  }
}
.rbc-time-header {
  &.rbc-overflowing {
    border-right-color: $border-color;
  }
  .rbc-rtl &.rbc-overflowing {
    border-left-color: $border-color;
  }
  > .rbc-row:first-child {
    border-bottom-color: $border-color;
  }
  > .rbc-row.rbc-row-resource {
    border-bottom-color: $border-color;
  }
}
.rbc-time-header-content {
  border-left-color: $border-color;
  .rbc-rtl & {
    border-right-color: $border-color;
  }
  > .rbc-row.rbc-row-resource {
    border-bottom-color: $border-color;
  }
}
.rbc-time-content {
  border-top-color: $border-color;
  > * + * > * {
    border-left-color: $border-color;
  }
  .rbc-rtl & > * + * > * {
    border-right-color: $border-color;
  }
}
