.sponsoredContentCard {
    .cardImage {
        max-width: 14rem;
    }
    .cardHeading {
        background-color: $body-bg;
        // back
    }
}
@media (max-width: 768.98px) {
    .avgDuration {
        height: 10rem;
    }
    .sponsoredContentCard {
        .cardImage {
            max-width: fit-content;
        }
    }
}
.word-break {
    word-break: break-word;
}