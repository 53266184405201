.form-control {
  &:focus {
    border-color: var(--#{$variable-prefix}primary-tint-40);
  }
}
.form-select {
  padding-right: $input-padding-x * 2;
  &:focus {
    border-color: var(--#{$variable-prefix}primary-tint-40);
  }
  &.form-select-sm {
    background-position: right $form-select-padding-x-sm center;
    padding-right: $form-select-padding-x-sm * 4;
  }
}
