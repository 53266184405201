.react-select-customize {
  & > div {
    min-height: 3.36rem;
    font-size: $input-btn-font-size;
    background-color: $white;
    color: $body-color;
    border-color: $border-color;
    border-width: $input-btn-border-width;
    border-radius: $border-radius;
    box-shadow: none !important;
    &:hover,
    &:focus {
      border-color: tint-color($component-active-bg, 50%);
    }
  }
}
