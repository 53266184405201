// https://material.io/components/text-fields
.custom-form-floating {
  > .form-control {
    height: 3.36rem;
    &::placeholder {
      color: transparent;
    }
    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-b;
      padding-bottom: $form-floating-input-padding-b;
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-b;
      padding-bottom: $form-floating-input-padding-b;
    }
  }
  textarea.form-control {
    min-height: 8rem;
  }
  > .form-select {
    padding-top: $form-floating-padding-y;
    padding-bottom: $form-floating-padding-y;
    height: 3.36rem;
    padding-right: $form-floating-padding-x * 2.5;
    padding-left: $form-floating-padding-x;
  }
  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      background-color: $white;
      opacity: 1;
    }
  }
  @include floating-input-varient(
    $custom-floating-input,
    $custom-floating-input-active,
    $custom-floating-input-padding-y,
    $custom-floating-input-padding-x,
    $custom-floating-input-height
  );
  &.custom-form-floating.flex-grow-1 {
    .form-control {
      height: 100%;
    }
  }
}

.custom-form-floating-sm {
  @include floating-input-varient(
    $custom-floating-input-sm,
    $custom-floating-input-active-sm,
    $custom-floating-input-padding-y-sm,
    $custom-floating-input-padding-x-sm,
    $custom-floating-input-height-sm
  );
}

.custom-form-floating-lg {
  @include floating-input-varient(
    $custom-floating-input-lg,
    $custom-floating-input-active-lg,
    $custom-floating-input-padding-y-lg,
    $custom-floating-input-padding-x-lg,
    $custom-floating-input-height-lg
  );
}

.custom-form-floating-flat {
  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
    &.is-valid,
    &.is-invalid {
      box-shadow: none;
    }
  }
}

// ======================================== New Custom ======================================

// Floating form

// .form-floating>textarea.form-control {
//   height: 10rem;
//   resize: none;
// }

// .form-floating>label {
//   color: $gray-600;
//   border-radius: $border-radius-sm;
// }

// .form-floating {
//   &>textarea.form-control {
//     height: 10rem;
//     resize: none;
//   }

//   textarea.form-control:focus,
//   textarea.form-control:not(:placeholder-shown) {
//     padding: 1.6rem 1.4rem;
//   }

//   .form-control:focus~label,
//   .form-control:not(:placeholder-shown)~label,
//   .form-select~label,
//   .form-control-plaintext~label {
//     padding: 0.2rem 0.4rem;
//     background-color: $white;
//     color: $black;
//     height: auto;
//     width: auto;
//     border-radius: $border-radius-sm;
//     left: 1.2rem;
//   }

// }

// .form-check {
//   min-height: 2rem;
//   padding-left: 2rem;

//   .form-check-input {
//     width: 1.8rem;
//     height: 1.8rem;
//     margin-top: 0;
//     margin-right: 1rem;

//     &:checked {
//       background-color: var(--bs-secondary);
//       border-color: var(--bs-secondary);
//     }
//   }

//   input[type='radio'] {
//     &:checked {
//       background-color: $primary;
//       border-color: $primary;
//     }
//   }

//   .form-check-label {
//     font-size: $h4-font-size;
//     color: $black;
//   }

//   &.incorrect {
//     .form-check-input:checked {
//       background-color: $danger;
//       border-color: $danger;

//       &+.form-check-label {
//         color: $danger;
//       }
//     }
//   }

//   &.correct {
//     .form-check-input:checked {
//       background-color: var(--bs-secondary);
//       border-color: var(--bs-secondary);

//       &+.form-check-label {
//         color: var(--bs-secondary);
//       }
//     }
//   }
// }
